@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();

//$cor-bradesco: #e52645
$mat-bradesco: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #e52645,
  600: #e52645,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);





// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

$light-app-primary: mat-palette($mat-blue); /*$mat-bradesco*/

$light-app-accent:  mat-palette($mat-green, 400);

// The warn palette is optional (defaults to red).
$light-app-warn:  mat-palette($mat-red, 400);

// Create the theme object (a Sass map containing all of the palettes).
$light-app-theme: mat-light-theme($light-app-primary, $light-app-accent, $light-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($light-app-theme);


// Define an alternate dark theme.
$dark-primary: mat-palette($mat-blue);
$dark-accent:  mat-palette($mat-green, 400);
$dark-warn:    mat-palette($mat-red, 400);
$dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

// Define an alternate bradesco theme.
$bradesco-primary:    mat-palette($mat-bradesco);
$bradesco-accent:     mat-palette($mat-green, 400);
$bradesco-warn:       mat-palette($mat-red, 400);
$bradesco-theme:      mat-light-theme($bradesco-primary, $bradesco-accent, $bradesco-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with 
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme. 
.theme-dark,
.theme-gray {
  @include angular-material-theme($dark-theme);
}

.theme-bradesco {
  @include angular-material-theme($bradesco-theme);
}