#page-container{
    padding-top: 5px;
}

mat-form-field
{
    width: 100%;
}

.app-content
{
    padding-right: 2%;
}

.table > thead > tr, .table > tbody > tr:nth-child(even) {
    background-color: white;
    /*  */
}

.table > tbody > tr:nth-child(odd)
{
    background-color: #efefef;
}

/* .mat-tab-labels
{
    background-color: #e52645;
}

.mat-tab-label-content{
    color: #fff;
} */

.form-control {
    padding: .5rem .75rem!important;
}

.label-middle{
    margin-top: 8px
}

.control-label{
    font-weight: bold;
}

DIV.simple-notification-wrapper {
    z-index: 999999 !important;
}