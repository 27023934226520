@import url(~jsoneditor/dist/jsoneditor.min.css);
@import url(~@ng-select/ng-select/themes/default.theme.css);
:focus {
  outline: none !important; }

button[mat-fab], button[mat-mini-fab], button[mat-raised-button],
a[mat-fab],
a[mat-mini-fab],
a[mat-raised-button] {
  text-decoration: none; }

button[mat-raised-button].mat-warn, button[mat-fab].mat-warn, button[mat-mini-fab].mat-warn, button[mat-raised-button].mat-accent, button[mat-fab].mat-accent, button[mat-mini-fab].mat-accent,
a[mat-raised-button].mat-warn,
a[mat-fab].mat-warn,
a[mat-mini-fab].mat-warn,
a[mat-raised-button].mat-accent,
a[mat-fab].mat-accent,
a[mat-mini-fab].mat-accent {
  color: #fff; }

button[mat-fab], button[mat-mini-fab],
a[mat-fab],
a[mat-mini-fab] {
  color: #fff; }

button.btn-lg,
a.btn-lg {
  padding: 0.8rem 1.2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

button.btn-sm,
a.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.mat-menu-content a[mat-menu-item] {
  text-decoration: none; }
  .mat-menu-content a[mat-menu-item]:hover, .mat-menu-content a[mat-menu-item]:focus {
    color: rgba(0, 0, 0, 0.87); }

[mat-tab-nav-bar], .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

[mdInput] {
  width: 100%; }

mat-input-container.mat-icon-left {
  position: relative;
  padding-left: 36px; }
  mat-input-container.mat-icon-left .mat-input-wrapper {
    position: relative; }
  mat-input-container.mat-icon-left .mat-input-infix > mat-icon {
    position: absolute;
    top: 0;
    left: -32px;
    right: auto; }

.mat-input-container .mat-icon.material-icons {
  font-size: 24px; }

.mat-radio-group-spacing mat-radio-button {
  margin-right: 20px; }
  .mat-radio-group-spacing mat-radio-button:last-child {
    margin-right: 0; }

mat-input-container:not(.ng-pristine).ng-invalid .mat-input-underline {
  border-color: #EF5350; }
  mat-input-container:not(.ng-pristine).ng-invalid .mat-input-underline .mat-input-ripple {
    background-color: #EF5350; }

mat-input-container:not(.ng-pristine).ng-valid .mat-input-underline {
  border-color: #66BB6A; }
  mat-input-container:not(.ng-pristine).ng-valid .mat-input-underline .mat-input-ripple {
    background-color: #66BB6A; }

.app-sidebar [mat-button], .app-sidebar [mat-raised-button], .app-sidebar [mat-fab], .app-sidebar [mat-icon-button], .app-sidebar [mat-mini-fab] {
  font-weight: normal;
  min-width: inherit; }

.quickview-inner .mat-list-item .mat-list-item-content {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .quickview-inner .mat-list-item .mat-list-item-content > p {
    flex: 1 1 auto;
    margin: 0; }
  .quickview-inner .mat-list-item .mat-list-item-content .mat-secondary {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 0;
    justify-content: flex-end; }

.app-header .mat-button {
  min-width: 60px; }

body .cdk-overlay-container {
  z-index: 1001; }

.page-with-tabs > .page-title {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 30px 15px 30px 39px; }
  .page-with-tabs > .page-title h2 {
    margin: 0;
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #2196F3; }

.page-with-tabs > mat-tab-group > mat-tab-header {
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.typo-styles dt {
  display: block;
  float: left;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-top: 5px; }

.typo-styles dd {
  display: block;
  margin-left: 80px;
  margin-bottom: 20px; }

.typo-styles .typo-styles__demo {
  margin-bottom: 8px; }

.page-icons .card .fa,
.page-icons .card .material-icons,
.page-icons .card .wi {
  color: rgba(0, 0, 0, 0.5); }

.page-icons .card .fa,
.page-icons .card .wi {
  font-size: 20px;
  margin: 5px; }

.theme-gray .page-icons .card .fa,
.theme-gray .page-icons .card .material-icons,
.theme-gray .page-icons .card .wi,
.theme-dark .page-icons .card .fa,
.theme-dark .page-icons .card .material-icons,
.theme-dark .page-icons .card .wi {
  color: rgba(255, 255, 255, 0.7);
  opacity: .7; }

.page-grids .grid-structure .row {
  margin-top: .8rem; }
  .page-grids .grid-structure .row .widget-container {
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.1);
    padding: 10px 15px 12px;
    font-size: 0.875rem;
    min-height: 0;
    border-radius: 0.2rem; }

.color-palette {
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 60px; }
  .color-palette ul {
    margin: 0;
    padding: 0; }
  .color-palette .dark {
    color: rgba(0, 0, 0, 0.87); }
  .color-palette .color-group {
    padding-bottom: 40px; }
  .color-palette .color-group:first-child,
  .color-palette .color-group:nth-of-type(3n+1),
  .color-palette .color-group:last-child:first-child,
  .color-palette .color-group:last-child:nth-of-type(3n+1) {
    clear: left;
    margin-left: 0; }
  .color-palette .color-group li.divide,
  .color-palette .color-group:last-child li.divide {
    border-top: 4px solid #fafafa; }
  .color-palette .color-group li.color,
  .color-palette .color-group:last-child li.color {
    padding: 15px; }
  .color-palette .color-group li,
  .color-palette .color-group:last-child li {
    list-style-type: none; }
  .color-palette .color-group li.main-color,
  .color-palette .color-group:last-child li.main-color {
    border-bottom: 4px solid #fafafa; }
  .color-palette .color-group li.main-color .name,
  .color-palette .color-group:last-child li.main-color .name {
    display: block;
    margin-bottom: 60px; }
  .color-palette .color-group li.color .hex,
  .color-palette .color-group:last-child li.color .hex {
    float: right;
    text-transform: uppercase; }

.page-auth {
  background-color: #e9ecef;
  min-height: 100%;
  background-size: cover;
  padding: 0 10px; }
  .page-auth .card {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 40px 30px; }
  .page-auth .logo {
    font-size: 32px;
    margin-bottom: 1.3em; }
    .page-auth .logo.text-center {
      padding: 0; }
    .page-auth .logo a {
      font-weight: normal;
      text-decoration: none; }
  .page-auth .main-body {
    position: relative;
    max-width: 480px;
    margin: 0 auto;
    padding: 50px 0 20px; }
    @media (min-width: 768px) {
      .page-auth .main-body {
        padding-top: 150px; } }
  .page-auth .additional-info {
    color: #868e96;
    background-color: rgba(0, 0, 0, 0.04);
    padding: 10px 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.875rem; }
    .page-auth .additional-info a {
      color: #636c72; }
    .page-auth .additional-info .divider-h {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0 15px; }
  .page-auth.page-confirm-email .logo {
    font-size: 24px;
    margin-bottom: .8em; }
  .page-auth .confirm-mail-icon {
    text-align: center; }
    .page-auth .confirm-mail-icon .material-icons {
      color: #636c72;
      font-size: 100px; }

.page-err {
  background-color: #343a40;
  height: 100%;
  position: relative; }
  .page-err .err-container {
    background-color: #343a40;
    padding: 45px 10px 0; }
    @media (min-width: 768px) {
      .page-err .err-container {
        padding: 100px 0 0; } }
  .page-err .err {
    color: #fafafa; }
    .page-err .err h1 {
      margin-bottom: 35px;
      color: #fafafa;
      color: rgba(255, 255, 255, 0.8);
      font-size: 150px;
      font-weight: 300;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }
      @media (min-width: 768px) {
        .page-err .err h1 {
          font-size: 180px; } }
    .page-err .err h2 {
      color: #fafafa;
      color: rgba(255, 255, 255, 0.6);
      margin: 0;
      font-weight: 300;
      font-size: 28px;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .page-err .err h2 {
          font-size: 36px; } }
  .page-err .err-body {
    padding: 20px 10px; }
  .page-err .btn-goback {
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: #fff;
    border-color: rgba(255, 255, 255, 0.8); }
    .page-err .btn-goback:hover, .page-err .btn-goback:focus, .page-err .btn-goback:active, .page-err .btn-goback.active {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1); }
    .open .page-err .btn-goback.dropdown-toggle {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1); }
  .page-err .footer {
    position: absolute;
    bottom: 20px;
    width: 100%; }

.page-lock {
  height: 100%;
  background-size: cover; }
  .page-lock .lock-centered {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -65px; }
    @media screen and (min-width: 768px) {
      .page-lock .lock-centered {
        margin-top: -75px; } }
  .page-lock .lock-container {
    position: relative;
    max-width: 420px;
    margin: 0 auto; }
  .page-lock .lock-box {
    position: absolute;
    left: 0;
    right: 0; }
    .page-lock .lock-box .lock-user {
      background: #fff;
      width: 50%;
      float: left;
      height: 50px;
      line-height: 50px;
      margin-top: 50px;
      padding: 0 20px;
      border-left-radius: 0.2rem 0 0 0.2rem;
      color: #868e96; }
    .page-lock .lock-box .lock-img img {
      position: absolute;
      border-radius: 50%;
      left: 40%;
      width: 80px;
      height: 80px;
      border: 6px solid #fff;
      background: #fff; }
      @media screen and (min-width: 768px) {
        .page-lock .lock-box .lock-img img {
          left: 33%;
          width: 150px;
          height: 150px;
          border: 10px solid #fff; } }
    .page-lock .lock-box .lock-pwd {
      background: #fff;
      width: 50%;
      float: right;
      height: 50px;
      line-height: 50px;
      padding: 0 0 0 50px;
      margin-top: 50px;
      border-right-radius: 0 0.2rem 0.2rem 0;
      color: #2196F3; }
      @media screen and (min-width: 768px) {
        .page-lock .lock-box .lock-pwd {
          padding: 0 0 0 80px; } }
      .page-lock .lock-box .lock-pwd input {
        width: 80%;
        height: 50px;
        color: #495057;
        border: 0; }
      .page-lock .lock-box .lock-pwd .btn-submit {
        position: absolute;
        top: 50%;
        right: 20px;
        color: rgba(0, 0, 0, 0.87); }
        .page-lock .lock-box .lock-pwd .btn-submit .material-icons {
          line-height: 50px;
          height: 50px; }

.page-profile .profile-header {
  position: relative;
  margin: 0 0 15px;
  padding: 50px 30px 90px;
  background-size: cover; }

.page-profile .profile-img {
  display: inline-block;
  margin-right: 20px; }
  .page-profile .profile-img img {
    max-width: 120px;
    height: auto;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(0, 0, 0, 0.2); }

.page-profile .profile-social {
  display: inline-block; }
  .page-profile .profile-social > a {
    margin-right: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.page-profile .profile-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 10px 30px;
  color: #fafafa; }
  .page-profile .profile-info ul {
    margin: 0; }

.page-profile img.media-object {
  border-radius: 0.2rem; }

.page-invoice {
  color: rgba(0, 0, 0, 0.87); }
  .page-invoice .invoice-wrapper {
    padding: 0 0 30px;
    background-color: #fff; }

.invoice-inner {
  padding: 15px 15px 30px;
  background-color: #fff; }
  .invoice-inner .invoice-sum li {
    margin-bottom: 5px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0.2rem; }
  .invoice-inner .table.table-bordered {
    border: 0; }
  .invoice-inner .table .bg-color-dark > th {
    border: 0; }

.page-dashboard h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 30px; }

.page-dashboard .box {
  position: relative;
  border-radius: 0.2rem; }
  .page-dashboard .box .box-top,
  .page-dashboard .box .box-bottom {
    height: 100px;
    padding: 32px 15px;
    font-size: 40px;
    line-height: 1;
    text-align: center;
    font-weight: 300; }
    .page-dashboard .box .box-top .material-icons,
    .page-dashboard .box .box-bottom .material-icons {
      font-size: 40px; }
  .page-dashboard .box .box-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .page-dashboard .box .box-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center; }
    .page-dashboard .box .box-info span {
      height: 24px;
      display: inline-block;
      padding: 4px 10px;
      text-transform: uppercase;
      line-height: 14px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 11px;
      color: #868e96;
      border-radius: 1em; }

.theme-gray .page-dashboard .box .box-info span {
  background-color: #444; }

.theme-dark .page-dashboard .box .box-info span {
  background-color: #3a4047; }

.vprogressbar-container {
  height: 250px;
  margin-top: 25px;
  border-bottom: rgba(221, 221, 221, 0.3) 2px solid;
  position: relative; }
  .vprogressbar-container.brand-info {
    border-color: #00BCD4; }
  .vprogressbar-container.brand-success {
    border-color: #66BB6A; }
  .vprogressbar-container .vprogressbar {
    padding-left: 10px; }
    .vprogressbar-container .vprogressbar li {
      position: relative;
      height: 248px;
      width: 35px;
      background: rgba(221, 221, 221, 0.3);
      margin-right: 18px;
      float: left;
      list-style: none; }
  .vprogressbar-container .vprogressbar-percent {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%; }
  .vprogressbar-container .vprogressbar-legend {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-left: 0;
    padding: 5px 10px;
    text-align: left;
    border-radius: 0.2rem;
    background: rgba(255, 255, 255, 0.15); }
    .vprogressbar-container .vprogressbar-legend li {
      display: block;
      font-size: 11px;
      margin-bottom: 5px;
      list-style: none; }
    .vprogressbar-container .vprogressbar-legend .vpointer {
      height: 10px;
      width: 10px;
      display: inline-block;
      position: relative;
      top: 1px;
      margin-right: 5px; }
  .vprogressbar-container .vprogressbar-info {
    color: #ccc; }

.theme-gray .box-info .box-icon .material-icons,
.theme-dark .box-info .box-icon .material-icons {
  color: rgba(255, 255, 255, 0.54); }

.theme-gray .box-info .box-num,
.theme-dark .box-info .box-num {
  color: rgba(255, 255, 255, 0.54); }

.page-maintenance .top-header {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 32px;
  line-height: 1; }
  .page-maintenance .top-header a.logo {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87); }

.page-maintenance .content {
  max-width: 1140px;
  margin: 50px auto 0; }

.page-maintenance .main-content {
  margin-bottom: 80px; }
  .page-maintenance .main-content h1 {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 15px; }
  .page-maintenance .main-content p {
    font-size: 22px; }

.theme-dark .page-maintenance .top-header a.logo,
.theme-gray .page-maintenance .top-header a.logo {
  color: #fff; }

.page-about .hero.hero-bg-img {
  background-size: cover;
  padding: 0; }

.page-about .hero .hero-inner {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 90px 0; }

.page-about .hero .hero-title,
.page-about .hero .hero-tagline {
  color: #fff; }

.page-about .stat-container {
  margin-bottom: 30px; }
  .page-about .stat-container .stat-item {
    margin-bottom: 20px;
    border-bottom: 2px solid #f5f5f5; }
  .page-about .stat-container .stat-num {
    display: block;
    color: #2196F3;
    font-size: 72px;
    font-weight: 300;
    line-height: 66px; }
  .page-about .stat-container .stat-desc {
    display: inline-block;
    margin-bottom: -2px;
    padding-bottom: 20px;
    border-bottom: 2px solid #2196F3;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold; }

.page-about .article:nth-of-type(1) {
  padding-top: 75px; }

.page-about .space-bar {
  padding: 3px;
  border-radius: 0.2rem;
  margin-right: 5px; }

.page-terms h4 {
  margin-top: 2em;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase; }

.blog-item {
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  margin-top: 70px;
  padding: 70px 0 10px; }
  .blog-item:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0; }
  .blog-item h2 {
    font-size: 35px;
    line-height: 1; }
    .blog-item h2 a {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 300;
      text-decoration: none; }
  .blog-item .blog-info {
    margin: 10px 0; }
    .blog-item .blog-info > span {
      margin-right: 8px; }
    .blog-item .blog-info .avatar {
      width: 30px;
      height: auto;
      border-radius: 50%; }
    .blog-item .blog-info .date {
      opacity: .7; }
    .blog-item .blog-info .category {
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 0.2rem;
      background-color: rgba(0, 0, 0, 0.08); }
      .blog-item .blog-info .category a {
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
        opacity: .7; }
  .blog-item .desc {
    font-size: 16px;
    opacity: .7; }

.theme-gray .blog-item h2 a,
.theme-dark .blog-item h2 a {
  color: rgba(255, 255, 255, 0.7); }

.theme-gray .blog-item .blog-info .category a,
.theme-dark .blog-item .blog-info .category a {
  color: rgba(255, 255, 255, 0.7); }

body p-breadcrumb .ui-breadcrumb {
  background: transparent;
  border-radius: 0;
  text-transform: uppercase;
  border-width: 0px;
  font-size: 12px !important; }
  body p-breadcrumb .ui-breadcrumb .pi-chevron-right:before {
    content: "/"; }
  body p-breadcrumb .ui-breadcrumb LI.ui-breadcrumb-home A.ui-menuitem-link {
    font-size: 18px !important; }
  body p-breadcrumb .ui-breadcrumb .ui-menuitem-link {
    color: #002A61 !important;
    /*#002A61 $light-blue*/
    font-size: 13px !important; }
  body p-breadcrumb .ui-breadcrumb .ui-menuitem-link:hover {
    text-decoration: underline !important; }
  body p-breadcrumb .ui-breadcrumb .ui-menuitem-link[href="#"] {
    color: #999 !important;
    cursor: default; }
  body p-breadcrumb .ui-breadcrumb .ui-menuitem-link[href="#"]:hover {
    text-decoration: unset !important; }

.mat-calendar-content {
  height: 100%; }

.pi-sort-up,
.pi-sort-down,
.ui-sortable-column.ui-state-highlight {
  color: black !important; }

.mat-calendar-table {
  height: 80%; }

app-weekday-selection {
  width: 100%;
  padding: 0 15px; }

.mat-step-header .mat-step-icon,
.mat-step-header .mat-step-icon-not-touched {
  display: none; }

.mat-step-header[aria-selected=true] {
  background: #2196F3; }

.mat-step-header[aria-selected=true]:hover {
  background: #69b4f1; }

.mat-step-text-label {
  font-size: larger; }

.mat-step-header[aria-selected=true] .mat-step-text-label {
  color: white; }

.mat-horizontal-stepper-header-container .mat-step-header:nth-child(1) {
  border-left: 1px solid #999; }

.mat-step-header {
  margin-bottom: 10px;
  border-right: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999; }

.mat-horizontal-stepper-header-container {
  display: flex; }

.mat-horizontal-stepper-header-container > * {
  flex: 1; }

.mat-stepper-horizontal-line {
  display: none; }

.mat-horizontal-content-container {
  padding: 0px !important; }

app-elasticsearch-filter mat-card {
  margin-bottom: 1em; }

button.bg-success {
  color: white; }
  button.bg-success[disabled]:not(.mat-stroked-button) {
    background-color: #e4e4e4 !important;
    color: rgba(0, 0, 0, 0.26) !important;
    cursor: not-allowed; }
    button.bg-success[disabled]:not(.mat-stroked-button) span {
      background-color: transparent !important; }
    button.bg-success[disabled]:not(.mat-stroked-button):hover {
      background-color: lightgray !important; }

.box-header.bg-color {
  background-color: #2196f3;
  color: #fff;
  border-radius: 3px 3px 0px 0px; }

.box-header.squared {
  border-radius: 0; }

.box-header button {
  top: -9px; }
  .box-header button.mat-stroked-button {
    border-width: 2px; }

.box-header .mat-stroked-button {
  transition: all 100ms ease-in-out;
  background-color: transparent !important;
  float: right; }
  .box-header .mat-stroked-button.bg-success {
    border-color: white;
    color: white; }
    .box-header .mat-stroked-button.bg-success:hover {
      background-color: #66BB6A !important; }
      .box-header .mat-stroked-button.bg-success:hover .mat-button-focus-overlay {
        opacity: 0; }
    .box-header .mat-stroked-button.bg-success[disabled] {
      border-color: rgba(0, 0, 0, 0.26);
      color: rgba(0, 0, 0, 0.66);
      background-color: rgba(156, 156, 156, 0.26) !important;
      cursor: not-allowed; }

.box-header i.slide-icon {
  width: 25px;
  height: 15px;
  float: left;
  cursor: pointer; }

.theme-bradesco .box-header.bg-color {
  background-color: #e52645;
  color: #fff;
  border-radius: 3px 3px 0px 0px; }

.box-flex {
  display: flex;
  flex-direction: row; }
  .box-flex.align-bottom {
    align-items: flex-end; }
  .box-flex.align-center {
    align-items: flex-center; }
  .box-flex.align-top {
    align-items: flex-start; }

.mat-checkbox label {
  font-weight: bold;
  margin-top: 6px; }

.ui-paginator-bottom {
  height: 34px;
  background: white !important;
  line-height: 20px; }

.ui-paginator-prev,
.ui-paginator-next,
.ui-paginator-last,
.ui-paginator-first {
  color: #2196F3 !important; }

.ui-paginator-element {
  font-size: 1.25em; }

.ui-paginator-pages {
  margin-top: -7px; }

p-paginator .ui-paginator-bottom .ui-paginator-pages > .ui-state-active {
  border-radius: 0;
  background: #2196F3; }

.theme-bradesco .ui-paginator-prev,
.theme-bradesco .ui-paginator-next,
.theme-bradesco .ui-paginator-last,
.theme-bradesco .ui-paginator-first {
  color: #e52645 !important; }

.theme-bradesco p-paginator .ui-paginator-bottom .ui-paginator-pages > .ui-state-active {
  border-radius: 0;
  background: #e52645; }

#page-container .ui-table .ui-table-tbody > tr.hover:hover {
  background-color: #e5fde2  !important; }

/*#page-container */
.ui-table .ui-table-tbody > tr:nth-child(odd) {
  background-color: #f7f7f7 !important; }

.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tfoot > tr > td,
.ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: white !important; }

#page-container .ui-table .ui-table-tbody > tr.linha-selecionada {
  background-color: #77c0fa  !important;
  color: #131712  !important;
  cursor: pointer;
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
  border-bottom: #c8c8c8 solid 1px !important; }

td,
th {
  padding: 10px !important; }

.ui-table table {
  table-layout: auto !important;
  width: 100%; }

.ui-table .ui-table-data > tr > td.ui-editable-column {
  padding: 1px !important; }

.ui-table * {
  border: 0px !important; }

.top-nav-right > ul {
  font-size: 16px;
  font-weight: bolder; }

.mat-raised-button {
  min-width: 135px !important; }

.clickable {
  cursor: pointer; }

p-table {
  width: 100%; }

ng-select > div {
  border-radius: 0.2rem;
  height: 36px;
  padding: 3px 0; }

.app-sidebar.bg-color-light .nav a {
  color: #002A61 !important; }

.app-sidebar .nav ul li > a,
.app-sidebar .sidebar-content .nav > li > a {
  padding: 5px 15px !important;
  display: flex; }

.app-sidebar.bg-color-light .nav a:hover {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.iconeCard {
  position: absolute;
  left: -8px;
  top: -8px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 3px;
  /* border: #D9D9D9 solid 1px; */
  width: 30px;
  height: 30px;
  font-size: 25px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 1px; }

.label-input {
  display: flex;
  align-items: center;
  font-weight: bold; }

.mat-input-hint-wrapper mat-hint {
  color: red !important; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

.sidebar-content {
  padding-top: 5px; }

#body .mat-dialog-container select-dropdown > div {
  z-index: 9999;
  position: absolute; }

mat-dialog-container {
  overflow: hidden !important; }

.lista-importacao {
  margin: 1.5em;
  border: 1px solid darkgray;
  border-radius: 10px; }
  .lista-importacao .header {
    border-radius: 10px 10px 0 0;
    background-color: #F7F4ED;
    border-bottom: 1px solid darkgray;
    padding: 1em; }
  .lista-importacao .container-arquivo {
    height: 150px; }
    .lista-importacao .container-arquivo .lista-arquivo {
      list-style-type: none;
      position: absolute;
      margin-top: 10px; }
      .lista-importacao .container-arquivo .lista-arquivo .arquivo {
        display: inline-block;
        margin-right: 10px; }
    .lista-importacao .container-arquivo .display-mensagem-arquivo {
      position: relative;
      text-align: center;
      top: 45%;
      left: 35%;
      font-size: 2em; }
    .lista-importacao .container-arquivo .input-arquivo {
      opacity: 0;
      height: 100%;
      width: 100%; }

li .ui-state-active {
  background-color: transparent; }

.mat-check {
  background: white;
  border: 2px solid #888 !important;
  border-radius: 2px; }

#upload-header {
  padding: 10px; }

NG-SELECT {
  background: #ffffff !important; }

fieldset[disabled] button {
  background: #e9ecef !important;
  color: black;
  cursor: not-allowed; }

fieldset[disabled] ng-select,
fieldset[disabled] app-select {
  background: #e9ecef !important;
  cursor: not-allowed !important; }

fieldset[disabled] .mat-radio-label {
  cursor: not-allowed !important; }

fieldset[disabled] .mat-radio-outer-circle {
  border-color: gray !important; }

fieldset[disabled] .mat-radio-inner-circle {
  background-color: gray !important; }

fieldset[disabled] input {
  cursor: not-allowed; }

fieldset[disabled] button span,
fieldset[disabled] button mat-icon {
  color: black !important; }

fieldset[disabled] mat-icon {
  color: #babcbf !important;
  cursor: not-allowed; }

*[draggable="true"]:hover {
  cursor: move;
  background-color: #dfdfe0 !important; }

.droppable {
  outline: 1px dashed black !important; }

mat-radio-button {
  margin-right: 5px; }

.ui-listbox {
  width: 100% !important; }
  .ui-listbox .ui-widget-header {
    padding: 0px; }
    .ui-listbox .ui-widget-header .ui-inputtext {
      font-size: 14px !important; }
  .ui-listbox .ui-state-highlight {
    background-color: #2196F3; }
  .ui-listbox .ui-listbox-list-wrapper {
    height: 150px;
    overflow-y: auto; }

.small-negative-margin-left {
  margin-left: -25px; }

#body.swal2-height-auto {
  height: 100% !important; }

.mat-tab-label-active {
  opacity: 1 !important; }

.datepicker {
  height: 36px; }
  .datepicker .mat-input-flex.mat-form-field-flex {
    border: 1px solid lightgray !important;
    border-radius: 5px !important;
    height: 36px !important; }
  .datepicker .mat-form-field-label-wrapper.mat-input-placeholder-wrapper.mat-form-field-placeholder-wrapper {
    padding-top: 0px !important; }
  .datepicker input.mat-input-element {
    margin-top: 0px !important;
    padding-left: 0.5em; }
  .datepicker .mat-form-field-infix {
    padding: 0px;
    height: 36px;
    border: 0px; }
  .datepicker .mat-input-underline {
    visibility: hidden; }
  .datepicker .mat-input-suffix {
    padding-top: 5px;
    padding-right: 3px; }

/* Block UI Wrapper (Backdrop) */
div.block-ui-wrapper {
  background-color: rgba(240, 240, 240, 0.7);
  opacity: 1; }

/* Spinner */
div.block-ui-wrapper .loader {
  border-color: #fff;
  /* Circle color */
  border-left-color: #2196F3;
  /* Spinning section in circle color */ }

/* Message */
div.block-ui-spinner .message {
  color: #e52645;
  /* Loading text color */ }

app-preview-dados-estrategia table tbody td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.card-slide {
  transition: all 400ms ease-in-out;
  max-height: calc(100% - 10px);
  opacity: 1; }
  .card-slide.card-slide-out {
    max-height: 0px;
    opacity: 0;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0; }

.iconCardIn {
  transform: rotateZ(-180deg);
  margin-top: 5px;
  transition: all 400ms ease-in-out; }

.iconCardOut {
  transform: rotateZ(0deg);
  margin-top: -3px;
  transition: all 400ms ease-in-out; }

.close-button {
  float: right; }

.ui-listbox-list {
  overflow: hidden; }
  .ui-listbox-list .ui-listbox-item {
    position: relative; }
    .ui-listbox-list .ui-listbox-item .ui-chkbox {
      position: absolute;
      width: 102%;
      height: 102%; }
      .ui-listbox-list .ui-listbox-item .ui-chkbox .ui-chkbox-box {
        margin-top: 5px; }
    .ui-listbox-list .ui-listbox-item > span {
      margin-left: 20px; }

.mat-card > .mat-card-actions {
  margin: 0 0 -0.5rem 0 !important; }

.mat-card.can-hover:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: all 100ms ease-in-out; }

.form-group .mat-radio-group {
  display: block;
  margin-top: 0.6rem; }

.mat-tab-label-active {
  background: #2196F3; }

.theme-bradesco .mat-tab-label-active {
  background-color: #e52645 !important; }

.mat-dialog-container ng-select select-dropdown div.below, .mat-tab-body ng-select select-dropdown div.below {
  position: fixed !important;
  top: unset !important;
  left: unset !important;
  z-index: 1000; }

.mat-dialog-container ng-select.grid select-dropdown div.below,
.mat-tab-body ng-select.grid select-dropdown div.below {
  position: absolute !important;
  top: unset !important;
  left: unset !important;
  z-index: 1000; }

.mat-dialog-container ng-select.grid select-dropdown div.below div.options,
.mat-tab-body ng-select.grid select-dropdown div.below div.options {
  max-height: 130px !important; }

@media only screen and (max-width: 576px) {
  .mat-card.filtro > .mat-card-content .col-6:not(:nth-child(-n+2)),
  .mat-card.filtro > .mat-card-content .col-12:not(:first-child),
  .box-body .col-6:not(:nth-child(-n+2)),
  .box-body .col-12:not(:first-child) {
    margin-top: 1rem;
    text-align: left !important; }
  .mat-card.filtro > .mat-card-actions .col-12:not(:first-child) {
    margin-top: 0.5rem;
    text-align: left !important; } }

app-card-with-header > .box-body > * {
  width: 100%;
  padding: 0 1em; }

.single-list-box .mat-list-item:hover, .mat-list-item.selected {
  border-radius: 5px;
  cursor: pointer;
  color: white !important;
  background-color: #2196F3; }

.numeric-column {
  text-align: right; }

.date-column {
  text-align: center; }

.list-box-label {
  padding: 0px 5px;
  float: right;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 30px; }

app-custom-listbox select {
  height: 37px;
  width: 20px;
  border-color: lightgray;
  border-radius: 0 3px 3px 0;
  color: gray; }
  app-custom-listbox select option {
    color: black; }

app-date-math-filter {
  width: 100%; }

.mat-tab-label-content {
  color: white !important; }

p-table .ui-table textarea, p-table .ui-table ng-select, p-table .ui-table ng-select .below, p-table .ui-table .mat-radio-outer-circle {
  border: 1px solid lightgray !important; }

p-table .ui-table textarea, p-table .ui-table ng-select, p-table .ui-table ng-select .below {
  border-radius: 5px; }

mwl-text-input-autocomplete-menu .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  width: 100%;
  left: 0 !important;
  padding: 0.9em; }

mwl-text-input-autocomplete-menu .dropdown-menu li > a {
  width: 100%;
  display: inline-block; }

mwl-text-input-autocomplete-menu .dropdown-menu li > a:hover {
  background: var(--blue);
  color: white; }

UL.MenuPrincipal.nav, UL.MenuPrincipal.nav UL {
  font-size: 13px !important; }

.tab-top .mat-tab-label:not(.mat-tab-label-active) {
  background-color: rgba(0, 0, 0, 0.3); }

.tab-middle .mat-tab-label {
  color: #333333 !important;
  background-color: #f4f4f4;
  border-top: 1px solid #aaa8a8 !important;
  border-left: 1px solid #aaa8a8 !important;
  border-right: 1px solid #aaa8a8 !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-left: 1px;
  margin-right: 1px; }
  .tab-middle .mat-tab-label .mat-tab-label-content {
    color: #333333 !important; }

.tab-middle .mat-tab-label:hover:not(.mat-tab-label-active) {
  background-color: #dadada !important; }

.tab-middle .mat-tab-label.mat-tab-label-active {
  border-top: 1px solid white !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  z-index: 2000 !important;
  color: white !important;
  background-color: #2196F3 !important; }
  .tab-middle .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
    color: white !important; }

.theme-bradesco .mat-icon.mat-primary {
  color: #e52645 !important; }

.theme-bradesco .tab-middle .mat-tab-label.mat-tab-label-active {
  background-color: #e52645 !important; }

.tab-middle .mat-tab-labels {
  padding-left: 5px; }

json-editor .jsoneditor {
  border-color: #2196f3; }

json-editor .jsoneditor-menu {
  background-color: #2196f3;
  border-bottom: 1px solid #2196f3; }

json-editor .jsoneditor-menu a.jsoneditor-poweredBy {
  font-size: 0pt !important; }

json-editor,
json-editor .jsoneditor,
json-editor > div,
json-editor jsoneditor-outer {
  height: 500px; }

PRE.prettyjson {
  font-weight: 400;
  background-color: #282727;
  color: #FFF;
  font-size: 11px;
  line-height: 14px; }
  PRE.prettyjson .number-line {
    color: #adadaf;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */ }
  PRE.prettyjson .string {
    color: #95c602; }
  PRE.prettyjson .number {
    color: #f2b619; }
  PRE.prettyjson .boolean {
    color: #0097f1; }
  PRE.prettyjson .null {
    color: #727990; }
  PRE.prettyjson .key {
    color: #fff; }

.none {
  display: none !important; }

.task, .task--warning, .task--danger, .task--info, .task--sucesso {
  border-left-width: 3px;
  padding: 4px;
  border-left-style: solid;
  font-size: 11px;
  position: relative;
  text-align: left;
  width: 95%;
  margin-top: 3px;
  line-height: 17px; }

.task--warning:hover, .task--danger:hover, .task--info:hover, .task--sucesso:hover {
  font-size: 11.5px;
  -xpedu-transform: scale(1.12);
  -ms-transform: scale(1.12);
  transform: scale(1.12);
  cursor: default; }

.task--warning {
  border-left-color: #fdb44d;
  background: #fef0db;
  color: #fc9b10; }

.task--danger {
  border-left-color: #fa607e;
  background: rgba(253, 197, 208, 0.7);
  color: #f8254e; }

.task--info {
  border-left-color: #4786ff;
  background: rgba(218, 231, 255, 0.7);
  color: #0a5eff; }

.task--sucesso {
  border-left-color: #237d0c;
  background: rgba(219, 255, 218, 0.7);
  color: #237d0c; }

.task--mensage {
  font-size: 10px;
  text-align: center;
  color: silver;
  padding-top: 15px; }

.task--mensage-sucess {
  font-size: 10px;
  text-align: center;
  color: green;
  padding-top: 15px; }

.evento {
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
  min-height: 100px; }

.dia {
  position: relative;
  height: 20px;
  width: 100%;
  /* background-color: white; */
  /* float: right;*/
  /* z-index: 100; */
  text-align: right;
  font-size: 18px;
  color: #312f2f; }

.diaAtual {
  background-color: #ffeeb8; }

.diaFuturo {
  background-color: #f6f6f6; }

.tooltip-example {
  text-align: center;
  padding: 0 50px; }

.tooltip-example [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center; }

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: left;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0; }

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid; }

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent; }

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent; }

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black; }

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent; }

.ng-tooltip-show {
  opacity: 1; }

.active-nav {
  background-color: #b4b4b4;
  color: #04bd4b !important; }
