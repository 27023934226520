.page-invoice {
  color: $body-color;
  .invoice-wrapper {
    padding: 0 0 30px;
    background-color: $white;
  }

}

.invoice-inner {
  padding: 15px 15px 30px;
  background-color: $white;

  .invoice-sum {
    li {
      margin-bottom: 5px;
      padding: 10px;
      background-color: rgba(#000, .05);
      border-radius: $border-radius;
    }
  }

  .table {
    &.table-bordered {
      border: 0;
    }

    .bg-color-dark {
      > th {
        border: 0;
      }
    }
  }
}

