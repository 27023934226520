$skin-gray: rgba(221,221,221,.3);

.page-dashboard {
  h3 {
    font-size: 22px;
    font-weight: normal;
    line-height: 1;
    margin: 0 0 30px;
  }

  .box {
    position: relative;
    border-radius: $border-radius-lg;

    .box-top,
    .box-bottom {
      height: 100px;
      padding: 32px 15px;
      font-size: 40px;
      line-height: 1;
      text-align: center;
      font-weight: $display1-weight;
      .material-icons {
        font-size: 40px;
      }
    }

    .box-bottom {
      border-top: 1px solid rgba(0,0,0,.15);
    }

    .box-info {
      position: absolute;
      width: 100%;
      top: 50%;
      margin-top: -12px;
      text-align: center;

      span {
        height: 24px;
        display: inline-block;
        padding: 4px 10px;
        text-transform: uppercase;
        line-height: 14px;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.15);
        font-size: 11px;
        color: $text-muted;
        border-radius: 1em;
      }
    }
  }
}
// Dark theme
.theme-gray {
  .page-dashboard {
    .box {
      .box-info {
        span {
          background-color: $theme_gray_page_bg;
        }
      }
    }
  }
}
.theme-dark {
  .page-dashboard {
    .box {
      .box-info {
        span {
          background-color: $theme_dark_page_bg;
        }
      }
    }
  }
}


.vprogressbar-container {
  height: 250px;
  margin-top: 25px;
  border-bottom: $skin-gray 2px solid;
  position: relative;
  &.brand-info { border-color: theme-color("info"); }
  &.brand-success { border-color: theme-color("success"); }
  .vprogressbar {
    padding-left: 10px;
    li {
      position: relative;
      height: 248px;
      width: 35px;
      background: $skin-gray;
      margin-right: 18px;
      float: left;
      list-style: none;
    }
  }
  .vprogressbar-percent {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
  .vprogressbar-legend {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-left: 0;
    padding: 5px 10px;
    text-align: left;
    border-radius: $border-radius;
    background: rgba(255,255,255,.15);

    li {
      display:  block;
      font-size: 11px;
      margin-bottom: 5px;
      list-style:  none;
    }

    .vpointer {
      height: 10px;
      width: 10px;
      display: inline-block;
      position: relative;
      top: 1px;
      margin-right: 5px;
    }
  }

  .vprogressbar-info {
    color: #ccc;
  }
}

// Dark theme
// --------------------------------------------------
.theme-gray,
.theme-dark {
  .box-info {
    .box-icon {
      .material-icons {
        color: rgba($theme_dark_text_color, .54);
      }
    }
    .box-num {
      color: rgba($theme_dark_text_color, .54);
    }
  }
}
