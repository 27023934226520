// default light theme

// for boxed-layout
// html,
// body,
// .app-header {
//   background-color: $body-bg;
// }

// .app-page-container .app-content-wrapper {
//   background-color: $page_bg;
// }
// @media only screen and (max-width: $l_screen_sm_max) {
//   .app-page-container {
//     background-color: $page_bg;
//   }
// }

//   // header
//   .app-header-inner {
//     &.bg-color-light,
//     &.bg-color-dark,
//     &.bg-color-primary,
//     &.bg-color-success,
//     &.bg-color-info,
//     &.bg-color-warning,
//     &.bg-color-danger {
//       background-color: $theme_dark_header_bg_color;
//       color: $theme_gray_text_color;
//       a {
//         color: $theme_gray_text_color;
//       }
//     }
//   }
// }




// shared 
//.theme-gray,
.theme-bradesco {
  // scaffolding
  //color: $theme_dark_text_color;
  a {
    &:hover,
    &:focus {
      color: $link-color;
    }
  }

  // sidebar
  .app-sidebar {
    .sidebar-header {
      background-color: $red-bradesco;
      color: #fff;
      a {
        color: #fff;
      }
      a.collapsednav-toggler {
        color: #fff;
        opacity: .5;
      }
    }
  }

  // overlay
  .app-overlay {
    background: rgba(0,0,0,.7);
    color: $theme_bradesco_text_color;
    input {
      color: $theme_bradesco_text_color;
    }

    a.overlay-close {
      color: $text-muted;
    }
  }

  // sidebar
  // .app-sidebar {
  //   .sidebar-header {
  //     color: $sidebar_text_color;
  //     a {
  //       color: $sidebar_text_color !important;
  //     }
  //   }
  // }

  .app-footer {
    .brand {
      color: $theme_bradesco_text_color;
    }
  }
}



// Dark
// --------------------------------------------------
.theme-bradesco {

  // sidebar
  .app-sidebar {
    //background-color: $theme_bradesco_sidebar_bg_color;

    //.sidebar-header {
    //  background-color: $theme_bradesco_sidebar_bg_color;
    //}
    //.sidebar-footer {
    //  background-color: $theme_bradesco_sidebar_bg_color;
    //}

    // overrides light bg sidebar style
    .nav {
      color: $sidebar_text_color;
      a {
        color: $sidebar_text_color;
      }
    }
  }

  // page-container
  //.app-page-container {
  //  .app-content-wrapper{
  //    background-color: $theme_dark_page_bg;
  //  }
  //}
  //@media only screen and (max-width: $l_screen_sm_max) {
  //  .app-page-container {
  //    background-color: $theme_dark_page_bg;
  //  }
  //}

  // header
  .app-header-inner {
    &.bg-color-light,
    &.bg-color-dark,
    &.bg-color-primary,
    &.bg-color-success,
    &.bg-color-info,
    &.bg-color-warning,
    &.bg-color-danger {
      background: linear-gradient(90deg, rgb(18, 16, 16) 0%, rgba(0,0,0,1) 35%, rgba(229,38,69,1) 100%);// $theme_bradesco_header_bg_color;
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}

// e.g. for pages like maintaince
.theme-bradesco {
  background-color: $theme_bradesco_page_bg;
}
// .theme-gray {
//   background-color: $theme_gray_page_bg;
// }
